import React, { useCallback, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Toggle } from 'react-style-guide';

import { CommunityInfo, CommunityVisibility } from '../types';
import { groupAnnouncementsConfig } from '../translation.config';
import communityLinksService from '../services/communityLinksService';

export type CommunityLayoutConfigSectionProps = {
  communityInfo: CommunityInfo;
  groupId: number;
} & WithTranslationsProps;

const CommunityLayoutConfigSection = ({
  communityInfo,
  groupId,
  translate
}: CommunityLayoutConfigSectionProps): JSX.Element => {
  const [localCommunityInfoVisibility, setLocalCommunityInfoVisibility] = useState(
    communityInfo.visibility
  );
  const [isLoading, setIsLoading] = useState(false);

  const toggleShowGuildedBanner = useCallback(async () => {
    const newCommunityInfoVisibility =
      localCommunityInfoVisibility === CommunityVisibility.Public
        ? CommunityVisibility.Private
        : CommunityVisibility.Public;

    // Optimistically update the UI
    setLocalCommunityInfoVisibility(newCommunityInfoVisibility);
    try {
      setIsLoading(true);
      await communityLinksService.updateLinkedCommunity(groupId, {
        visibility: newCommunityInfoVisibility
      });
    } catch (error) {
      setLocalCommunityInfoVisibility(localCommunityInfoVisibility);
    } finally {
      setIsLoading(false);
    }
  }, [localCommunityInfoVisibility, groupId]);

  return (
    <div className='section-content community-config-section'>
      <div className='community-config-section-row'>
        <span>{translate('Label.JoinCommunityVisibility')}</span>
        <Toggle
          className='community-config-section-row-toggle'
          isOn={localCommunityInfoVisibility === CommunityVisibility.Public}
          isDisabled={isLoading}
          onToggle={toggleShowGuildedBanner}
        />
      </div>
    </div>
  );
};

export default withTranslations(CommunityLayoutConfigSection, groupAnnouncementsConfig);
