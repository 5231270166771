import React, { useCallback, useEffect, useState } from 'react';
import { Loading, SystemFeedbackProvider } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';

import AnnouncementChannelConfigurator from '../components/AnnouncementChannelConfigurator';
import AnnouncementsUpsell from '../components/AnnouncementsUpsell';
import communityLinksService from '../services/communityLinksService';
import { CommunityInfo } from '../types';
import { Group } from '../../shared/types';
import { logGroupPageExposureEvent } from '../../shared/utils/logging';
import { EventContext as SharedEventContext } from '../../shared/constants/eventConstants';
import ErrorDisplay from '../components/ErrorDisplay';
import { groupAnnouncementsConfig } from '../translation.config';
import CommunityLayoutConfigSection from '../components/CommunityLayoutConfigSection';
import CommunityRolesSyncedConfigSection from '../components/CommunityRolesSyncedConfigSection';

export type GroupAnnouncementsConfigSectionProps = {
  group: Group;
  displayGroupRolesSynced: boolean;
} & WithTranslationsProps;

const GroupAnnouncementsConfigSection = ({
  group,
  displayGroupRolesSynced,
  translate
}: GroupAnnouncementsConfigSectionProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const [communityInfo, setCommunityInfo] = useState<CommunityInfo | null>(null);

  const fetchCommunityInfo = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const communityInfoResponse: CommunityInfo = await communityLinksService.getLinkedCommunity(
        group.id
      );
      setCommunityInfo(communityInfoResponse);
    } catch (e) {
      setError(e as Error);
      setCommunityInfo(null);
    } finally {
      setIsLoading(false);
    }
  }, [group]);

  const refetchCommunityInfo = useCallback(async () => {
    if (isLoading) {
      return;
    }
    await fetchCommunityInfo();
  }, [isLoading, fetchCommunityInfo]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchCommunityInfo();
  }, [fetchCommunityInfo]);

  useEffect(() => {
    logGroupPageExposureEvent({
      groupId: group.id,
      exposureType: 'groupAnnouncementsConfigSection',
      context: SharedEventContext.ConfigureGroup
    });
  }, [group.id]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorDisplay
        title={translate('Heading.CommunityDialogError')}
        message={translate('Description.CommunityDialogError')}
      />
    );
  }

  if (!communityInfo) {
    return (
      <div className='section configure-group-guilded'>
        <div className='container-header'>
          <h2>{translate('Heading.Announcements')}</h2>
        </div>
        <div className='section-content'>
          <div className='group-announcements-config-section-upsell'>
            <div className='group-announcements-config-section-upsell-icon' />
            <AnnouncementsUpsell
              refetchCommunityInfo={refetchCommunityInfo}
              group={group}
              className='group-announcements-config-section-upsell-body'
              eventContext={SharedEventContext.ConfigureGroup}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <SystemFeedbackProvider>
      <div className='section configure-group-guilded'>
        <div className='container-header'>
          <h2>{translate('Heading.Announcements')}</h2>
        </div>
        <div className='section-content'>
          <AnnouncementChannelConfigurator
            communityInfo={communityInfo}
            refetchCommunityInfo={refetchCommunityInfo}
            groupId={group.id}
          />
        </div>
        <div className='container-header'>
          <h2>{translate('Heading.Community')}</h2>
        </div>
        <CommunityLayoutConfigSection communityInfo={communityInfo} groupId={group.id} />
        {displayGroupRolesSynced && (
          <CommunityRolesSyncedConfigSection communityInfo={communityInfo} groupId={group.id} />
        )}
      </div>
    </SystemFeedbackProvider>
  );
};

export default withTranslations(GroupAnnouncementsConfigSection, groupAnnouncementsConfig);
