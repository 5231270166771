interface CommunityInfo {
  communityId: string;
  name: string;
  description: string;
  iconAssetId: number;
  announcementChannelId: string;
  rolesSynced?: boolean;
  visibility: CommunityVisibility;
}

interface ValidateCommunityResponse {
  nameIsValid?: boolean;
}

interface ValidateCommunityRequest {
  name?: string;
}

export enum CommunityVisibility {
  Private = 'private',
  Public = 'public'
}

interface GetGuildedChannelsResponse {
  channels: GuildedChannel[];
}

interface GuildedChannel {
  id: string;
  name: string;
}

interface GuildedUser {
  userId: string;
  username: string;
  profilePicture: string;
}

interface GuildedServer {
  serverId: string;
  name: string;
  iconUrl: string | null;
  memberCount: number;
  isAdmin: boolean;
}

interface GuildedServersResponse {
  servers: GuildedServer[];
}

interface ShoutInfoResponse {
  // The shout ID
  id: string;

  // The ID of the Guilded community the shout was made in
  communityId: string;

  // The Guilded channel ID for the announcement channel the shout was created in
  announcementChannelId: string;

  // The ID of the corresponding announcement on Guilded
  announcementId: string;

  // The title of the shout
  title: string;

  // The text content of the shout
  content: string;

  // The URL for the Guilded image showcased on the shout, if one exists
  imageURL: string;

  // The current number of likes the shout has
  likeCount: number;

  // The ISO 8601 timestamp that the shout was created at
  createdAt: string; // DateTime in C# can be represented as string in ISO 8601 format in TypeScript

  // The ISO 8601 timestamp that the shout was last updated at
  updatedAt: string; // Similarly, DateTime is represented as a string in ISO 8601 format

  // The roblox user id that created this announcement post
  createdBy: number; // long in C# is mapped to number in TypeScript

  // The boolean state of roblox user interaction with shout.
  userHasReactedToShout: boolean;

  // The boolean flag indicating if the shout's like (or reaction) count should be displayed
  areReactionCountsVisible: boolean;
}

interface UpdateCommunitySettings {
  announcementChannelId?: string;
  visibility?: CommunityVisibility;
  rolesSynced?: boolean;
}

interface UpdateShoutReactionRequest {
  announcementId: string;
  groupId: number;
}

// user returned from user api
interface UserData {
  id: number;
  hasVerifiedBadge: boolean;
  displayName: string;
  name: string;
}

interface UserResponse {
  data: UserData[];
}

interface CreateCommunityResponse {
  communityInfo: CommunityInfo;
  loginToken: string;
}

interface ApiError extends Error {
  status: number;
}

type CatalogItemDetails = {
  owned: boolean;
};

interface GroupDetailsPolicies {
  displayMarketplaceEmbed: boolean;
}

export {
  CommunityInfo,
  GuildedUser,
  GuildedServer,
  GuildedServersResponse,
  GetGuildedChannelsResponse,
  GuildedChannel,
  ShoutInfoResponse,
  UpdateCommunitySettings,
  UserData,
  UserResponse,
  CreateCommunityResponse,
  UpdateShoutReactionRequest,
  ApiError,
  ValidateCommunityResponse,
  ValidateCommunityRequest,
  CatalogItemDetails,
  GroupDetailsPolicies
};
