import React, { useCallback, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Toggle, useSystemFeedback } from 'react-style-guide';

import { CommunityInfo, ApiError } from '../types';
import { groupAnnouncementsConfig } from '../translation.config';
import communityLinksService from '../services/communityLinksService';

export type CommunityRolesSyncedConfigSectionProps = {
  communityInfo: CommunityInfo;
  groupId: number;
} & WithTranslationsProps;

const CommunityRolesSyncedConfigSection = ({
  communityInfo,
  groupId,
  translate
}: CommunityRolesSyncedConfigSectionProps): JSX.Element => {
  const { systemFeedbackService, SystemFeedbackComponent } = useSystemFeedback();
  const [localRolesSynced, setLocalRolesSynced] = useState(communityInfo.rolesSynced ?? false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConflictError, setIsConflictError] = useState<boolean>(false);

  const toggleRoleSync = useCallback(async () => {
    const newRolesSynced = !localRolesSynced;
    setIsConflictError(false);

    // Optimistically update the UI
    setLocalRolesSynced(newRolesSynced);
    try {
      setIsLoading(true);
      await communityLinksService.updateLinkedCommunity(groupId, {
        rolesSynced: newRolesSynced
      });
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.status === 409) {
        setIsConflictError(true);
      } else {
        systemFeedbackService.warning(translate('NetworkError'));
      }
      setLocalRolesSynced(localRolesSynced);
    } finally {
      setIsLoading(false);
    }
  }, [localRolesSynced, groupId, systemFeedbackService, translate]);

  return (
    <React.Fragment>
      <div
        className={`section-content community-config-section ${
          isConflictError ? 'community-config-section-has-error' : ''
        }`}>
        <div className='community-config-section-row'>
          <span>{translate('Label.SyncRolesToGuildedCommunity')}</span>
          <Toggle
            className='community-config-section-row-toggle'
            isOn={localRolesSynced}
            isDisabled={isLoading}
            onToggle={toggleRoleSync}
          />
        </div>
      </div>
      {isConflictError && (
        <span className='community-config-section-error text-error'>
          {translate('Label.SyncRolesToGuildedCommunityConflictError')}
        </span>
      )}
      <SystemFeedbackComponent />
    </React.Fragment>
  );
};

export default withTranslations(CommunityRolesSyncedConfigSection, groupAnnouncementsConfig);
